<template>
  <div>
    <PaymentModal />
    <div class="packages-wrapper" v-if="user.package != null">
      <div>
        <div
          class="packages-card"
        >
          <div>
            <div>
              <h4 class="heading text-capitalize">{{ user.package.title }}</h4>
              <p class="price text-capitalize">
                ${{ user.package.amount }} {{ user.package.duration_in_words }}
              </p>
              <!-- <p class="description text-capitalize">
                {{ pack.description }}
              </p> -->
            </div>
            <div class="line-seperator"></div>
            <div class="packages-facilities">
              <div
                class="facility-content"
                v-for="(packIndex, index) in user.package.points.split(',')"
                :key="index"
              >
                <img src="../../assets/images/Check icon.png" alt="" />
                <h6 class="text-capitalize">{{ packIndex }}</h6>
              </div>
            </div>
          </div>

          <!-- <button v-if="index % 2" :class="{ 'custom-btn': index % 2 }">
                Buy Now
              </button>
              <button class="white-btn" v-else @click="callModal(pack)">
                Buy Now
              </button> -->
        </div>
      </div>
    </div>
    <div class="packages-wrapper" v-else>
      <div>
        <div
          class="packages-card"
        >
          <div v-for="(package2, index) in packages.slice(0, 1)" :key="index">
            <div>
              <h4 class="heading text-capitalize">{{ package2.title }}</h4>
              <p class="price text-capitalize">
                ${{ package2.amount }} {{ package2.duration_in_words }}
              </p>
              <!-- <p class="description text-capitalize">
                {{ pack.description }}
              </p> -->
            </div>
            <div class="line-seperator"></div>
            <div class="packages-facilities">
              <div
                class="facility-content"
                v-for="(packIndex, index) in package2.points.split(',')"
                :key="index"
              >
                <img src="../../assets/images/Check icon.png" alt="" />
                <h6 class="text-capitalize">{{ packIndex }}</h6>
              </div>
            </div>
          </div>

          <!-- <button v-if="index % 2" :class="{ 'custom-btn': index % 2 }">
                Buy Now
              </button>
              <button class="white-btn" @click="callModal(pack)">
                Buy Now
              </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PaymentModal from "./PaymentModal.vue";
export default {
  components: { PaymentModal },
  name: "Packs",
  props: {
    packages: Array,
  },
  data() {
    return {
      string: "",
    };
  },
  methods: {
    callModal: function(pack) {
      this.$root.$emit("openPaymentModal", pack);
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    lists: function() {
      return this.string.split(",");
    },
  },
};
</script>

<style></style>
